export const hasLockedTable = (articleBodyTemplateString: string) => {
  const regexp = /"component":"(Table|CsvTable)","props":{(.*)}/g
  const matches = articleBodyTemplateString.match(regexp)

  if (matches?.length) {
    const hasLock = matches.map((match) => match.includes('PadlockIcon')).filter(Boolean)

    return hasLock.length > 0
  }

  return false
}
