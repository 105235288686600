import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getCrScamsArticleDataLayer = (pageInfo: PageInfo, articleName: string) => {
  const crScamsArticleDataLayer: DataLayerInfo = {
    ...pageInfo,
    topicName: 'scams',
    subtopicName: articleName,
    content_type: 'subtopic',
  }

  delete crScamsArticleDataLayer.sub_vertical
  delete crScamsArticleDataLayer.category

  return crScamsArticleDataLayer
}
