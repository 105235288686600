import type { ArticleType } from '../../../../routes'
import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getCrToolsArticleDataLayer = (pageInfo: PageInfo, articleType: ArticleType) => {
  const crToolsArticleDataLayer: DataLayerInfo = {
    ...pageInfo,
    topicName: pageInfo.sub_vertical,
    subtopicName: pageInfo.category,
    content_type: 'advice',
  }

  if (articleType === 'advice') {
    crToolsArticleDataLayer.content_type = 'Advice'
  }

  delete crToolsArticleDataLayer.sub_vertical
  delete crToolsArticleDataLayer.category

  return crToolsArticleDataLayer
}
