import { assertNever } from '@which/shared'

import { usePageProps } from '../../../shared/usePageProps'
import type {
  ArticleAboutWhichData,
  ArticleCrPageData,
  ArticleCrScamsPageData,
  ArticleCrToolsPageData,
  ArticleNewsPageData,
  ArticlePageVariantTypes,
  ArticlePolicyAndInsightPageData,
  ArticleReviewsMultiPageData,
  ArticleReviewsPageData,
  ArticleTemplateName,
} from '../article-page-types'
import { getAboutWhichArticlesComponents } from '../article-variants/about-which/components'
import { getCRArticleComponents } from '../article-variants/cr-article'
import { getCRScamsArticleComponents } from '../article-variants/cr-scams-article'
import { getCRToolsArticleComponents } from '../article-variants/cr-tools-article'
import { getNewsArticleComponents } from '../article-variants/news-article'
import { getPolicyAndInsightArticleComponents } from '../article-variants/policy-and-insight-article'
import { getReviewsMultiComponents } from '../article-variants/reviews-multi-page-article'
import { getReviewsComponents } from '../article-variants/reviews-single-page-article'
import { getComponents } from './base-components'

export const useArticlePageComponents = () => {
  const { template } = usePageProps()
  const articleTemplate = template as ArticleTemplateName

  return {
    getPageComponents(data: ArticlePageVariantTypes) {
      switch (articleTemplate) {
        case 'CR Tool Article':
          return getComponents(data, getCRToolsArticleComponents(data as ArticleCrToolsPageData))
        case 'CR Scams Article':
          return getComponents(data, getCRScamsArticleComponents(data as ArticleCrScamsPageData))
        case 'Article CR Single Page':
          return getCRArticleComponents(data as ArticleCrPageData)
        case 'Article Advice Single Page':
          return getComponents(data, getReviewsComponents(data as ArticleReviewsPageData))
        case 'Article Advice Multi Page':
          return getComponents(data, getReviewsMultiComponents(data as ArticleReviewsMultiPageData))
        case 'Article News Single Page':
          return getComponents(data, getNewsArticleComponents(data as ArticleNewsPageData))
        case 'Article About Which':
          return getComponents(data, getAboutWhichArticlesComponents(data as ArticleAboutWhichData))
        case 'Article Policy and Insight Single Page':
          return getComponents(
            data,
            getPolicyAndInsightArticleComponents(data as ArticlePolicyAndInsightPageData)
          )
        default:
          assertNever(articleTemplate)
      }
    },
  }
}
