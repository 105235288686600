export const getUpdatedAtDate = (timestamp: string | number) => {
  if (!timestamp) {
    return ''
  }

  const date = new Date(timestamp)

  const getDateValue = (dateOption: Intl.DateTimeFormatOptions) =>
    new Intl.DateTimeFormat('en-GB', dateOption).format(date)

  const day = getDateValue({ day: '2-digit' })
  const month = getDateValue({ month: '2-digit' })
  const year = getDateValue({ year: 'numeric' })

  return `${year}-${month}-${day}`
}
