import type { ArticleConfig } from '@which/glide-ts-types'

import type { PageInfo } from '../../../../shared/types'

export const getReviewsArticleDataLayer = (pageInfo: PageInfo, articleConfig: ArticleConfig) => {
  const pageType = pageInfo.isVideoArticle ? 'video-article' : pageInfo.pageType
  const reviewsArticleDataLayer: DataLayerInfo = {
    ...pageInfo,
    pageType,
    adviceType: articleConfig.adviceType,
    articleArea: articleConfig.articleArea,
    articleType: articleConfig.articleType,
    content_type: 'advice',
  }

  delete reviewsArticleDataLayer.isVideoArticle

  return reviewsArticleDataLayer
}

///////// IMPLEMENTATION /////////

type DataLayerInfo = PageInfo & { adviceType?: string; articleArea?: string; articleType?: string }
