import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getNewsArticleDataLayer = (pageInfo: PageInfo) => {
  const pageType = pageInfo.isVideoArticle ? 'news-video-article' : 'news-article'
  const newsArticleDataLayer: DataLayerInfo = {
    ...pageInfo,
    vertical: pageInfo.sub_vertical,
    sub_vertical: pageInfo.category,
    content_type: 'news',
    pageType,
  }

  delete newsArticleDataLayer.category
  delete newsArticleDataLayer.isVideoArticle

  return newsArticleDataLayer
}
