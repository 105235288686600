import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { assertNever } from '@which/shared'

import {
  useAboutWhichArticleQuery,
  useArticleCrPageQuery,
  useArticleCrScamsPageQuery,
  useArticleCrToolPageQuery,
  useArticleNewsPageQuery,
  useArticlePolicyAndInsightPageQuery,
  useArticleReviewsMultiPageQuery,
  useArticleReviewsPageQuery,
} from '../../../generated/frontend'
import { usePageProps } from '../../../shared/usePageProps'
import type { ArticleTemplateName } from '../article-page-types'

export const useArticlePageQuery = () => {
  const { pathname, search } = useLocation()
  const { template, context } = usePageProps()
  const articleTemplate = template as ArticleTemplateName

  const useQuery = useMemo(() => {
    switch (articleTemplate) {
      case 'CR Tool Article':
        return useArticleCrToolPageQuery
      case 'CR Scams Article':
        return useArticleCrScamsPageQuery
      case 'Article CR Single Page':
        return useArticleCrPageQuery
      case 'Article Advice Single Page':
        return useArticleReviewsPageQuery
      case 'Article Advice Multi Page':
        return useArticleReviewsMultiPageQuery
      case 'Article News Single Page':
        return useArticleNewsPageQuery
      case 'Article About Which':
        return useAboutWhichArticleQuery
      case 'Article Policy and Insight Single Page':
        return useArticlePolicyAndInsightPageQuery
      default:
        assertNever(articleTemplate)
    }
  }, [articleTemplate])

  return useQuery({
    variables: {
      context: context || '',
      url: `${pathname}${search}`,
    },
  })
}
