export const smoothScroll = async (targetPos: number, reducedMotion: boolean): Promise<void> => {
  await new Promise<void>((resolve) => {
    if (window.scrollY === targetPos) {
      resolve()
      return
    }

    const scrollHandler = () => {
      if (window.scrollY === targetPos) {
        window.removeEventListener('scroll', scrollHandler)
        resolve()
      }
    }

    window.addEventListener('scroll', scrollHandler)

    window.scrollTo({
      top: targetPos,
      ...(reducedMotion ? {} : { behavior: 'smooth' }),
    })
  })
}
