import { useParams } from 'react-router-dom'
import type { ArticleConfig } from '@which/glide-ts-types'
import { assertNever } from '@which/shared'

import type { ArticleType, PageParams } from '../../../routes'
import type { PageInfo } from '../../../shared/types'
import { usePageProps } from '../../../shared/usePageProps'
import type { ArticleTemplateName } from '../article-page-types'
import { getAboutWhichArticleDataLayer } from '../article-variants/about-which'
import { getCrArticleDataLayer } from '../article-variants/cr-article'
import { getCrScamsArticleDataLayer } from '../article-variants/cr-scams-article'
import { getCrToolsArticleDataLayer } from '../article-variants/cr-tools-article'
import { getNewsArticleDataLayer } from '../article-variants/news-article'
import { getPolicyAndInsightArticleDataLayer } from '../article-variants/policy-and-insight-article'
import { getReviewsArticleDataLayer } from '../article-variants/reviews-single-page-article'

export const useArticlePageDataLayer = () => {
  const { template } = usePageProps()
  const { articleName } = useParams<PageParams>()
  const articleTemplate = template as ArticleTemplateName

  return {
    getPageDatalayer(pageInfo: PageInfo, articleType: ArticleType, articleConfig: ArticleConfig) {
      switch (articleTemplate) {
        case 'Article CR Single Page':
          return getCrArticleDataLayer(pageInfo, articleType)
        case 'Article Advice Single Page':
        case 'Article Advice Multi Page':
          return getReviewsArticleDataLayer(pageInfo, articleConfig)
        case 'CR Tool Article':
          return getCrToolsArticleDataLayer(pageInfo, articleType)
        case 'CR Scams Article':
          return getCrScamsArticleDataLayer(pageInfo, articleName)
        case 'Article News Single Page':
          return getNewsArticleDataLayer(pageInfo)
        case 'Article About Which':
          return getAboutWhichArticleDataLayer(pageInfo)
        case 'Article Policy and Insight Single Page':
          return getPolicyAndInsightArticleDataLayer(pageInfo)
        default:
          assertNever(articleTemplate)
      }
    },
  }
}
