import React from 'react'

import classNames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import { AssociatedArticles } from '../../../../shared/components/AssociatedArticles/AssociatedArticles'
import { HideOnArticleScroll } from '../../../../shared/components/HideOnScroll'
import { PIArticleDate } from '../../../../shared/components/PolicyAndInsight/PIArticleDate/PIArticleDate'
import { PIArticleDownload } from '../../../../shared/components/PolicyAndInsight/PIArticleDownload/PIArticleDownload'
import { PIArticleInfo } from '../../../../shared/components/PolicyAndInsight/PIArticleInfo/PIArticleInfo'
import { SharingOptions } from '../../../../shared/components/Sharing/SharingOptions'
import { ShowMore } from '../../../../shared/components/ShowMore/ShowMore'
import { ZoomImageModal } from '../../../../shared/components/ZoomImageModal'
import {
  componentTypeMoreOn,
  componentTypeRelatedArticles,
} from '../../../../shared/constants/ga-variables'
import { renderArticleElements } from '../../../../shared/renderers/article-element-renderer'
import type { ArticlePolicyAndInsightPageData } from '../../article-page-types'
import { ArticleHero } from '../../components/ArticleHero/ArticleHero'
import { ArticleTags } from '../../components/ArticleTags/ArticleTags'
import { AuthorWrapper } from '../../components/Author/AuthorWrapper'
import { Header } from '../../components/Header/Header'
import { IntroParagraph } from '../../components/IntroParagraph/IntroParagraph'
import { JumpLinks } from '../../components/JumpLinks/JumpLinks'
import { WhichConnectMessage } from '../../components/WhichConnectMessage/WhichConnectMessage'
import styles from './PolicyAndInsightArticle.module.scss'

export const getPolicyAndInsightArticleComponents = (data: ArticlePolicyAndInsightPageData) => {
  const {
    articleBodyTemplate,
    articleConfig,
    articleExcelDataSheet,
    articlePdf,
    authors,
    header,
    heroImage,
    heroVideo,
    introParagraph,
    jumpLinks,
    moreOnArticles,
    relatedArticles,
    sharingData,
    tags,
    estimatedReadingTime,
  } = data

  const calculateFileSize = (fileSize: number) => {
    const fileSizeLength: number = fileSize.toString().length
    let calculatedFileSize = ''
    if (fileSizeLength >= 6) {
      calculatedFileSize = `${(
        Math.round((fileSize / 1000000 + Number.EPSILON) * 100) / 100
      ).toString()}MB`
    } else {
      calculatedFileSize = `${(
        Math.round((fileSize / 1000 + Number.EPSILON) * 100) / 100
      ).toString()}KB`
    }

    return calculatedFileSize
  }

  const createDownloadsArray = () => {
    const downloads: { label: string; link: string }[] = []
    if (articlePdf.length !== 0) {
      downloads.push({
        label: `PDF article (${calculateFileSize(articlePdf[0].file_size)})`,
        link: articlePdf[0].path,
      })
    }
    if (articleExcelDataSheet.length !== 0) {
      downloads.push({
        label: `Excel stats (${calculateFileSize(articleExcelDataSheet[0].file_size)})`,
        link: articleExcelDataSheet[0].path,
      })
    }

    return downloads
  }

  const getHeader = () => {
    if (articleConfig.articleType === 'Policy paper') {
      return { ...header, date: { ...header.date, rendered: '' } }
    }

    return { ...header }
  }

  const wrapContent = (children: any) => children

  const handleShowMoreOnJumplinkClick = () => {
    const showMoreBtn = document.querySelector<HTMLButtonElement>(
      '[data-testid="show-more-button"]'
    )

    if (showMoreBtn) {
      showMoreBtn.click()
    }
  }

  const articleElements = renderArticleElements(articleBodyTemplate)
  const useJumpLinkBar = jumpLinks.length > 3

  return {
    Authors: () => <AuthorWrapper authors={authors} withAuthorLink />,
    IntroParagraph: () => <IntroParagraph text={introParagraph} />,
    SharingOptionsHorizontal: () => (
      <HideOnArticleScroll>
        <SharingOptions
          {...(useJumpLinkBar && { className: styles.policyAndInsightArticleSharingOptions })}
          sharingData={sharingData}
        />
      </HideOnArticleScroll>
    ),
    ArticleBody: () => (
      <div className={classNames('article-wrapper', styles.policyAndInsightArticleWrapper)}>
        <ShowMore
          buttonLabel="Show more"
          contentRenderer={wrapContent}
          className={styles.policyAndInsightArticleShowMore}
        >
          <ZoomImageModal>
            <main>{articleElements}</main>
          </ZoomImageModal>
        </ShowMore>
      </div>
    ),
    SharingOptionsVertical: () => (
      <HideOnArticleScroll>
        <SharingOptions sharingData={sharingData} />
      </HideOnArticleScroll>
    ),
    ArticleHeader: () => {
      const heading = getHeader()
      return (
        <>
          <div className={styles.policyAndInsightArticleHeader}>
            <PIArticleInfo articleType={articleConfig.articleType} />
            <Header {...{ ...heading, date: { ...heading.date, rendered: '' } }} />
            <PIArticleDate
              date={heading?.date?.rendered}
              estimatedReadingTime={estimatedReadingTime}
            />
          </div>
        </>
      )
    },
    MoreOnLinks: ({ className }) => (
      <AssociatedArticles
        position="bottom"
        {...moreOnArticles}
        className={className}
        componentType={componentTypeMoreOn}
      />
    ),
    RelatedArticles: ({ className }) => (
      <AssociatedArticles
        position="bottom"
        {...relatedArticles}
        className={className}
        componentType={componentTypeRelatedArticles}
        {...(useJumpLinkBar && {
          className: classNames(className, styles.policyAndInsightArticleRelated),
        })}
      />
    ),
    PIArticleDownload: ({ className }) => (
      <PIArticleDownload
        className={className}
        {...(useJumpLinkBar && {
          className: classNames(className, styles.policyAndInsightArticleDownloads),
        })}
        files={createDownloadsArray()}
      />
    ),
    ArticleHero: () => (
      <ArticleHero heroImage={heroImage as ImageWithSources} heroVideo={heroVideo} />
    ),
    JumpLinks: () => {
      return (
        <JumpLinks
          links={jumpLinks}
          showJumpLinkBar={useJumpLinkBar}
          handleOnClick={handleShowMoreOnJumplinkClick}
        />
      )
    },
    WhichConnectMessage: () => (
      <WhichConnectMessage showMessage={articleConfig?.whichConnectMessage} />
    ),
    ArticleTags: () => <ArticleTags tags={tags} />,
  }
}
