import React from 'react'
import { GridItem } from '@which/seatbelt'

import { AssociatedArticles } from '../../../../shared/components/AssociatedArticles/AssociatedArticles'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { HideOnArticleScroll } from '../../../../shared/components/HideOnScroll'
import { SharingOptions } from '../../../../shared/components/Sharing/SharingOptions'
import { articleBodyRenderer } from '../../../../shared/renderers/article-body-renderer'
import type { ArticleAboutWhichData } from '../../article-page-types'
import { Header } from '../../components/Header'
import { JumpLinks } from '../../components/JumpLinks/JumpLinks'
import { WithHideOnArticleScroll } from '../../components/WithHideOnArticleScroll/WithHideOnArticleScroll'
import styles from './ArticleAboutWhich.module.scss'

export const getAboutWhichArticlesComponents = (data: ArticleAboutWhichData) => {
  const { breadcrumb, articleBodyTemplate, sharingData, jumpLinks, header, moreOnArticles } = data

  const handleShowMoreOnJumplinkClick = () => {
    const showMoreBtn = document.querySelector<HTMLButtonElement>(
      '[data-testid="show-more-button"]'
    )

    if (showMoreBtn) {
      showMoreBtn.click()
    }
  }

  return {
    ArticleHeader: () => (
      <GridItem span={{ large: 8, medium: 8 }} columnStart={{ medium: 2, large: 2 }}>
        <Header {...header} />
      </GridItem>
    ),
    AboutWhichHeader: () => <Breadcrumb {...breadcrumb} />,
    JumpLinks: () => (
      <GridItem span={{ large: 8, medium: 8 }} columnStart={{ medium: 2, large: 2 }}>
        <JumpLinks handleOnClick={handleShowMoreOnJumplinkClick} links={jumpLinks} />
      </GridItem>
    ),
    MoreOnLinks: ({ className }) =>
      WithHideOnArticleScroll(AssociatedArticles, {
        ...moreOnArticles,
        className,
        title: 'Additional links',
      }),
    ArticleBody: () => (
      <GridItem span={{ large: 8, medium: 8 }} columnStart={{ medium: 2, large: 2 }}>
        {articleBodyRenderer(articleBodyTemplate)}
      </GridItem>
    ),
    SharingOptionsVertical: () => (
      <SharingOptions
        sharingData={sharingData}
        orientation="vertical"
        className={styles.sharingOptionsVertical}
      />
    ),
    SharingOptionsHorizontal: () => (
      <HideOnArticleScroll>
        <SharingOptions sharingData={sharingData} />
      </HideOnArticleScroll>
    ),
  }
}
