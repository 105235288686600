import type { FunctionComponent } from 'react'
import React from 'react'
import { GridItem, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './BasicHeader.module.scss'

export const BasicHeader: FunctionComponent<Props> = ({ header, standfirst }) => {
  return (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <div data-testid="petition-header" className={styles.basicHeader}>
        <Typography
          tag="h1"
          textStyle="sb-text-heading-page-title"
          className={styles.basicHeaderTitle}
        >
          {header}
        </Typography>
        <Typography
          tag="p"
          textStyle="sb-text-heading-standfirst"
          className={styles.basicHeaderStandfirst}
        >
          {standfirst}
        </Typography>
      </div>
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  header: string
  standfirst: string
}
