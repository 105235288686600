import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getReviewsArticleDataLayer = (pageInfo: PageInfo) => {
  const reviewsArticleDataLayer: DataLayerInfo = {
    ...pageInfo,
    content_type: 'advice',
  }

  return reviewsArticleDataLayer
}
