import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@which/seatbelt'
import { ArrowUpIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import { smoothScroll } from '../../utils/smooth-scroll'
import styles from './BackToTop.module.scss'

export const BackToTop: FunctionComponent<Props> = ({
  buttonText = 'Back to top',
  buttonType = 'secondary',
}) => {
  const [backToTopVisible, setBackToTopVisible] = useState(false)
  const triggerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let prevY = 0

    const triggerObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.boundingClientRect.y < prevY) {
          setBackToTopVisible(true)
        } else if (entry.boundingClientRect.y > prevY) {
          setBackToTopVisible(false)
        }
        prevY = entry.boundingClientRect.y
      },
      {
        rootMargin: '0px',
      }
    )

    if (triggerRef.current) {
      triggerObserver.observe(triggerRef.current)
    }

    return () => triggerObserver.disconnect()
  }, [])

  const clickHandler = async () => {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion)').matches

    await smoothScroll(0, prefersReducedMotion)
    document.body.tabIndex = -1
    document.body.focus()
    document.body.removeAttribute('tabIndex')
  }

  return (
    <>
      <div ref={triggerRef} className={styles.triggerPoint} />
      <div
        className={classnames(styles.backToTop, { [styles.backToTopVisible]: backToTopVisible })}
      >
        <Button
          appearance={buttonType}
          onClick={clickHandler}
          aria-hidden={backToTopVisible ? false : true}
          data-which-id="pagenav-backtotop"
        >
          <ArrowUpIcon />
          {buttonText}
        </Button>
      </div>
    </>
  )
}

type Props = {
  buttonText?: string
  buttonType?: 'primary' | 'secondary'
}
