import { useFullUrl } from './useFullUrl'

export const useIsBroadbandProvidersArticle = () => {
  const { getFullUrl } = useFullUrl()
  const url = getFullUrl()

  return url.includes(
    'reviews/broadband/article/broadband-provider-reviews/best-broadband-providers'
  )
}
