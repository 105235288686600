import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getAboutWhichArticleDataLayer = (pageInfo: PageInfo) => {
  const aboutWhichDataLayer: DataLayerInfo = {
    ...pageInfo,
    content_type: pageInfo.vertical === 'help' ? 'help' : 'about-which',
  }
  delete aboutWhichDataLayer.vertical
  delete aboutWhichDataLayer.sub_vertical

  return aboutWhichDataLayer
}
