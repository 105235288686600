import { assertNever } from '@which/shared'

import type { ArticleType } from '../../../../routes'
import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getCrArticleDataLayer = (pageInfo: PageInfo, articleType: ArticleType) => {
  const crArticleDataLayer: DataLayerInfo = {
    ...pageInfo,
    topicName: pageInfo.sub_vertical,
    subtopicName: pageInfo.category,
    content_type: getCRArticleType(articleType) || 'Unknown CR article type',
  }

  delete crArticleDataLayer.sub_vertical
  delete crArticleDataLayer.category

  return crArticleDataLayer
}

///////// IMPLEMENTATION /////////

type ContentType = 'Advice' | 'Regulations' | 'Letters'

const getCRArticleType = (articleType: ArticleType): ContentType => {
  switch (articleType) {
    case 'advice':
      return 'Advice'
    case 'letter':
      return 'Letters'
    case 'regulation':
      return 'Regulations'
    default:
      return assertNever(articleType)
  }
}
