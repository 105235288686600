import { BackToTop } from '../../../shared/components/BackToTop/BackToTop'
import { Breadcrumb } from '../../../shared/components/Breadcrumb/Breadcrumb'
import { articleBodyRenderer } from '../../../shared/renderers/article-body-renderer'
import type { ArticlePageVariantTypes } from '../article-page-types'

export const getComponents = (data: ArticlePageVariantTypes, rest = {}) => {
  const { articleBodyTemplate, breadcrumb } = data

  return {
    ArticleBody: () => articleBodyRenderer(articleBodyTemplate),
    Breadcrumb: () => <Breadcrumb {...breadcrumb} />,
    BackToTop: () => <BackToTop />,
    ...rest,
  }
}
