import React from 'react'
import { GridItem } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import { AffiliateDisclaimer } from '../../../../shared/components/AffiliateDisclaimer/AffiliateDisclaimer'
import { AssociatedArticlesImages } from '../../../../shared/components/AssociatedArticlesImages/AssociatedArticlesImages'
import { HideOnArticleScroll } from '../../../../shared/components/HideOnScroll/HideOnArticleScroll'
import { LocalFooter } from '../../../../shared/components/LocalFooter'
import { PromoBlock } from '../../../../shared/components/PromoBlock/PromoBlock'
import { SharingOptions } from '../../../../shared/components/Sharing/SharingOptions'
import {
  componentTypeMoreOn,
  componentTypeRelatedArticles,
} from '../../../../shared/constants/ga-variables'
import type { ArticleNewsPageData } from '../../article-page-types'
import { ArticleHero } from '../../components/ArticleHero/ArticleHero'
import { ArticleTags } from '../../components/ArticleTags/ArticleTags'
import { AuthorWrapper } from '../../components/Author/AuthorWrapper'
import { GreenNCAPEmbed } from '../../components/GreenNCAPEmbed/GreenNCAPEmbed'
import { Header } from '../../components/Header/Header'
import { IntroParagraph } from '../../components/IntroParagraph/IntroParagraph'
import { WhichConnectMessage } from '../../components/WhichConnectMessage/WhichConnectMessage'
import styles from './ArticleNewsPage.module.scss'

export const getNewsArticleComponents = (data: ArticleNewsPageData) => {
  const {
    introParagraph,
    header,
    heroImage,
    heroVideo,
    authors,
    sharingData,
    relatedArticles,
    moreOnArticles,
    articleConfig,
    promoBlock,
    tags,
    optionalArticleData,
  } = data

  return {
    Authors: () => <AuthorWrapper authors={authors} withAuthorLink={true} />,
    IntroParagraph: () => <IntroParagraph text={introParagraph} />,
    SharingOptionsHorizontal: () => (
      <HideOnArticleScroll>
        <SharingOptions sharingData={sharingData} />
      </HideOnArticleScroll>
    ),
    SharingOptionsVertical: () => (
      <SharingOptions sharingData={sharingData} orientation="vertical" />
    ),
    ArticleHeader: () => <Header {...header} type="newsArticle" />,
    MoreOnLinks: ({ className }) => (
      <AssociatedArticlesImages
        className={className}
        position="bottom"
        componentType={componentTypeMoreOn}
        {...moreOnArticles}
      />
    ),
    RelatedArticles: ({ className }) => (
      <AssociatedArticlesImages
        className={className}
        position="bottom"
        componentType={componentTypeRelatedArticles}
        {...relatedArticles}
      />
    ),
    PromoBlock: () => <PromoBlock {...promoBlock} />,
    AffiliateDisclaimer: () => (
      <AffiliateDisclaimer affiliateDisclaimer={articleConfig?.affiliateDisclaimer} />
    ),
    ArticleHero: () => (
      <ArticleHero heroImage={heroImage as ImageWithSources} heroVideo={heroVideo} />
    ),
    WhichConnectMessage: () => (
      <WhichConnectMessage showMessage={articleConfig?.whichConnectMessage} />
    ),
    ArticleTags: () => <ArticleTags tags={tags} dataWhichId="news-tag" />,
    GreenNCAPEmbed: () => <GreenNCAPEmbed />,
    LocalFooter: optionalArticleData?.components?.localFooter
      ? () => (
          <GridItem className={styles.localFooter} span={{ medium: 12, large: 12 }}>
            <LocalFooter data={optionalArticleData.components.localFooter}></LocalFooter>
          </GridItem>
        )
      : () => null,
  }
}
