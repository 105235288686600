import React from 'react'

import type { ImageWithSources } from '../../../../generated/frontend'
import { AffiliateDisclaimer } from '../../../../shared/components/AffiliateDisclaimer/AffiliateDisclaimer'
import { AssociatedArticles } from '../../../../shared/components/AssociatedArticles/AssociatedArticles'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { HideOnArticleScroll } from '../../../../shared/components/HideOnScroll/HideOnArticleScroll'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { SharingOptions } from '../../../../shared/components/Sharing/SharingOptions'
import { TaxonomyConversionBanner } from '../../../../shared/components/TaxonomyConversionBanner/TaxonomyConversionBanner'
import {
  componentTypeMoreOn,
  componentTypeRelatedArticles,
} from '../../../../shared/constants/ga-variables'
import { articleBodyRenderer } from '../../../../shared/renderers/article-body-renderer'
import type { ArticleCrPageData } from '../../article-page-types'
import { ArticleHero } from '../../components/ArticleHero/ArticleHero'
import { AuthorWrapper } from '../../components/Author/AuthorWrapper'
import { Header } from '../../components/Header'
import { IntroParagraph } from '../../components/IntroParagraph/IntroParagraph'
import { JumpLinks } from '../../components/JumpLinks/JumpLinks'
import { WhichConnectMessage } from '../../components/WhichConnectMessage/WhichConnectMessage'
import { WithHideOnArticleScroll } from '../../components/WithHideOnArticleScroll/WithHideOnArticleScroll'

export const getCRArticleComponents = (data: ArticleCrPageData) => {
  const {
    articleBodyTemplate,
    articleConfig,
    authors,
    introParagraph,
    jumpLinks,
    sharingData,
    header,
    heroImage,
    heroVideo,
    moreOnArticles,
    relatedArticles,
    consumerRightsFooter,
    breadcrumb,
    consumerRightsNavigation,
    taxonomyConversionBanner,
  } = data

  const handleShowMoreOnJumplinkClick = () => {
    const showMoreBtn = document.querySelector<HTMLButtonElement>(
      '[data-testid="show-more-button"]'
    )

    if (showMoreBtn) {
      showMoreBtn.click()
    }
  }

  return {
    ArticleHeader: () => <Header {...header} />,
    IntroParagraph: () => <IntroParagraph text={introParagraph} />,
    ArticleBody: () => articleBodyRenderer(articleBodyTemplate),
    Breadcrumb: () => <Breadcrumb {...breadcrumb} localNavProps={consumerRightsNavigation} />,
    ArticleHero: () => (
      <ArticleHero heroImage={heroImage as ImageWithSources} heroVideo={heroVideo} />
    ),
    Authors: () => <AuthorWrapper authors={authors} />,
    JumpLinks: () => <JumpLinks handleOnClick={handleShowMoreOnJumplinkClick} links={jumpLinks} />,
    AffiliateDisclaimer: () => (
      <AffiliateDisclaimer affiliateDisclaimer={articleConfig?.affiliateDisclaimer} />
    ),
    WhichConnectMessage: () => (
      <WhichConnectMessage showMessage={articleConfig?.whichConnectMessage} />
    ),
    MoreOnLinks: ({ className }) =>
      WithHideOnArticleScroll(AssociatedArticles, {
        ...moreOnArticles,
        className,
        componentType: componentTypeMoreOn,
      }),
    RelatedArticles: ({ className }) =>
      WithHideOnArticleScroll(AssociatedArticles, {
        ...relatedArticles,
        className,
        componentType: componentTypeRelatedArticles,
      }),

    SharingOptionsHorizontal: () => (
      <HideOnArticleScroll>
        <SharingOptions sharingData={sharingData} />
      </HideOnArticleScroll>
    ),
    SharingOptionsVertical: () => (
      <SharingOptions sharingData={sharingData} orientation="vertical" />
    ),
    TaxonomyConversionBanner: () => <TaxonomyConversionBanner {...taxonomyConversionBanner} />,
    ConsumerRightsFooter: () => <LocalFooter data={consumerRightsFooter} />,
  }
}
