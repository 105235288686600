export const dynamicTableDatalayer = () => {
  const dtSections = document.querySelectorAll('section[data-dt-product-count]')
  const brandSet = new Set()
  let productsCount = 0
  let brands

  if (dtSections.length > 0) {
    dtSections.forEach((section) => {
      const { dtProductBrands, dtProductCount = '' } = (section as HTMLElement).dataset
      productsCount += parseInt(dtProductCount, 10)
      if (dtProductBrands) {
        dtProductBrands.split(',').forEach((brand) => brandSet.add(brand))
      }
    })

    if (productsCount > 0 && window?.dataLayer) {
      if (brandSet.size > 0) {
        brands = Array.from(brandSet).join('|')
      }
      const dynamicTableData = brands ? { productsCount, brands } : { productsCount }

      window.dataLayer.push(dynamicTableData)
    }
  }
}
